<template>
  <v-container>
    <v-row style="height: 85vh" align="center" justify="center">
      <v-col class="text-center" style="margin-top: -85px">
        <v-img
          alt="lecleire"
          class="mx-auto mb-4"
          min-width="220"
          :src="require('@/assets/images/logoLeCleire.png')"
          width="220"
        />

        <h1 class="text-h4 font-weight-thin mb-4">Inicio de sesión</h1>
        <h4 class="subheading">Gestión de supervisoras</h4>
      </v-col>
      <v-col>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              ref="alias"
              v-model="alias"
              label="Usuario"
              required
              v-on:keyup.enter="login()"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field
              ref="password"
              v-model="password"
              type="password"
              label="Contraseña"
              required
              persistent-hint
              hint=""
              v-on:keyup.enter="login()"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              @click="login()"
              class="float-right"
              small
              elevation="1"
              color="primary"
              >Iniciar sesión</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
export default {
  data() {
    return {
      alias: '',
      password: '',
    };
  },
  computed: {
    ...mapState('auth', { loading: 'isAuthenticatePending' }),
  },
  methods: {
    //
    ...mapMutations('auth', {
      unsetAuthenticatePending: 'unsetAuthenticatePending',
    }),
    login() {
      //
      if (this.$refs.alias.value == '') {
        this.$refs.alias.style.border = 'thin solid #FF4C25';
      } else if (this.$refs.password.value == '') {
        this.$refs.password.style.border = 'thin solid #FF4C25';
      } else {
        this.$store.state.globalLoading = true;
        //autenticarse
        this.$store
          .dispatch('auth/authenticate', {
            strategy: 'local',
            alias: this.alias,
            password: this.password,
          })
          .then(() => {
            this.$store.state.globalLoading = false;
            this.unsetAuthenticatePending();
            this.$router.push('/');
          })
          .catch((e) => {
            this.$refs.password.hint =
              'Error al iniciar sesión, por favor intentelo de nuevo.';
            this.$store.state.globalLoading = false;
            console.error(e);
          });
      }
    },
  },
};
</script>
